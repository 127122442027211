<template>
  <div>
    <a class="come-back" @click="$router.go(-1)">
      <div></div>
      Вернуться назад
    </a>
    <h2 class="title_categories">Редактирование документа</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddDocuments" ref="AddDocuments">
        <div class="content-sb">
          <p>Название документа<span>*</span></p>
          <input v-model="form.title" placeholder="Введите название документа" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Название документа (Англ)<span>*</span></p>
          <input v-model="form.titleEng" placeholder="Введите название документа (Англ)" type="text" name="titleEng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Ссылка</p>
          <input v-model="form.link" placeholder="Введите ссылку" type="text" name="link" class="input blue">
        </div>

        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required placeholder="Введите название документа" v-model="form.order" min="0" type="text" name="order" class="input blue">
        </div>

        <div class="content-sb">
          <p>Старый документ</p>
          <a target="_blank" :href="$store.getters.getDetailedDocument.url">Старый документ</a>
        </div>

        <div class="content-sb">
          <p>Документ</p>
          <input type="file" name="file"  class="input blue" @input="newFile">
        </div>

      <div class="content-sb">
        <p>Впишите названия категорий <span>*</span><br> (Итоги работы, Документы и тд.)</p>
        <tags @dataTags="dataTags" :startValue="tagList"/>
      </div>

      <div class="content-sb">
        <p>Впишите названия категорий <span>*</span><br> (Итоги работы, Документы и тд.) (Англ)</p>
        <tags @dataTags="dataTagsEng" :startValue="tagListEng"/>
      </div>

      <footer class="content-sb btns-create-form">
        <a @click="$router.go(-1)" class="btn-white">Отмена</a>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'GeneralEditingDocument',
  data () {
    return {
      systemTagList: [],
      tagList: [],
      tagListEng: [],
      form: {
        createDate: '',
        title: '',
        file: '',
        titleEng: '',
        order: 0,
        link: ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('setDetailedDocument', this.$route.params.id)
  },
  watch: {
    '$store.getters.getDetailedDocument' () {
      const dataArr = this.$store.getters.getDetailedDocument
      console.log(dataArr)
      this.form = {
        title: dataArr.name,
        titleEng: dataArr.nameEng,
        createDate: dataArr.create_date,
        file: '',
        order: dataArr.order,
        link: dataArr.link
      }
      this.tagList = dataArr.tagList
      this.tagListEng = dataArr.tagListEng
      this.systemTagList = dataArr.systemTagList

      this.$forceUpdate()
    }
  },
  methods: {
    AddDocuments () {
      const formData = new FormData()
      formData.append('systemTagList', JSON.stringify(this.$store.getters.getDetailedDocument.systemTagList))
      if (this.tagList) formData.append('tagList', JSON.stringify(this.tagList))
      if (this.tagListEng) formData.append('tagListEng', JSON.stringify(this.tagListEng))
      for (const key in this.form) {
        var item = this.form[key]
        if (item !== '') {
          formData.append(key, item)
        }
      }
      this.$store.dispatch('EditDocuments', {
        formData: formData, id: this.$store.getters.getDetailedDocument.id, systemTagList: this.systemTagList
      })
    },
    dataTags (value) {
      this.tagList = value
    },
    dataTagsEng (value) {
      this.tagListEng = value
    },
    newFile () {
      const file = event.target.files[0]
      this.form.file = file
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss" scoped>
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
  a{
    color: #0b6cc6;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
